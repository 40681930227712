
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { Button, Tabs, Tab, Typography } from '@material-ui/core';

import {
  Page,
} from '../components';

const ManualsPage = ({ classes, history, location }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const [manSignUp, setManSignUp] = useState('');
  const [manOffers, setManOffers] = useState('');
  const [manQuote, setManQuote] = useState('');
  const [manApi, setManApi] = useState('');
  const [tab, setTab] = useState(0);

  function handleTabChange(event, value) {
    setTab(value);
  }

  useEffect(() => {
    const fetchManSignUp = async () => {
      const result = await axios.get(
        `/manuals/${i18n.language}/manSignUp.html`
      );
      setManSignUp(result.data);
    };
    fetchManSignUp();

    const fetchManOffers = async () => {
      const result = await axios.get(
        `/manuals/${i18n.language}/manOffers.html`
      );
      setManOffers(result.data);
    };
    fetchManOffers();

    const fetchManQuote = async () => {
      const result = await axios.get(`/manuals/${i18n.language}/manQuote.html`);
      setManQuote(result.data);
    };
    fetchManQuote();

    const fetchManApi = async () => {
      const result = await axios.get(`/manuals/${i18n.language}/manApi.html`);
      setManApi(result.data);
    };
    fetchManApi();
  }, [i18n.language]);

  return (
    <Page>
      <Tabs className={classes.tabs} value={tab} onChange={handleTabChange}>
        <Tab
          label={t('register')}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
        <Tab
          label={t('quotation')}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
        <Tab
          label={t('offers')}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
        <Tab
          label={t('api')}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
      </Tabs>

      {tab === 0 ? (
        <div>
          <div dangerouslySetInnerHTML={{ __html: manSignUp }} />
        </div>
      ) : tab === 1 ? (
        <div dangerouslySetInnerHTML={{ __html: manQuote }} />
      ) : tab === 2 ? (
        <div>
          <div dangerouslySetInnerHTML={{ __html: manOffers }} />
        </div>
      ) : (
        <div>
          <div dangerouslySetInnerHTML={{ __html: manApi }} />
        </div>
      )}
    </Page>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.searchPage;
  return {
    tabs: {},
    tabRoot: {},
    tabSelected: {},

    dialog: {
      overflowY: 'auto',
      height: '70vh',
    },

    iframe: {
      border: 0,
      width: '100%',
      height: '99%',
    },
  };
};

export default withStyles(styles)(ManualsPage);
