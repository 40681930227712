import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import {
  MenuItem,
  InputLabel,
  FormControl,
  TextField as MuiSelect,
} from '@material-ui/core';


const SelectFine = ({
  classes,
  className,
  inputClasses,
  items,
  value,
  label,
  textColor,
  labelColor,
  variant,
  onChange,
}) => {
  function handleChange(event) {
    onChange(event.target.value);
  }

  return (
        <MuiSelect
          select
          value={value}
          label={label}
          variant={variant}
          onChange={handleChange}
          className={classes.root}
          InputProps={
            {style: {
                fontSize: 'small',
                color: {textColor}
               }
            }
          } // font size of input text
          InputLabelProps={
            {style: {
                fontSize: 'medium',
                color: {labelColor}
               }
            }
          } // font size of label text
        >
          {items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.title}
            </MenuItem>
          ))}
        </MuiSelect>
  );
};

const styles = (theme) => ({
  input: {
    backgroundColor: '#fff',
  },
  root: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
    "&:after": {
      borderBottomColor: "#fff",
    },
  },
  inputMarginDense: {
    paddingLeft: theme.spacing(1)/2,
    paddingTop: theme.spacing(1)/2,
    paddingBottom: theme.spacing(1)/2,
  },
});

export default withStyles(styles)(SelectFine);
