import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Badge from "@material-ui/core/Badge";

import { useTranslation } from 'react-i18next';
import * as basketApi from '../api/basket-api';
import { getCookie } from '../utils/cookies';

const PageTabs = ({ props, classes, width, isLoggedIn }) => {
  const [currentTab, setCurrentTab] = useState(window.location.pathname);

  const handleChange = (value) => {
    setCurrentTab(value);
  };

  const isScrollable = isWidthDown('sm', width);
  const { t } = useTranslation();

  const [basketCount, setBasketCount] = useState(0 );

  async function getBasketCount() {

    if (isLoggedIn)
      try{
        const result = await basketApi.totals();

        if (result && Array.isArray(result) && result[0].hasOwnProperty('countTotal')) {
          const newValue = result[0].countTotal;
          if (newValue !== basketCount) {
            setBasketCount(newValue);
          }
        }
        else {
          setBasketCount(0);
        }
      } catch(error) {
      }
    else {
      setBasketCount(0);
    }

  }

  useEffect(() => {

    const checkBasket = setInterval(() => {
        getBasketCount();
    }, 2000);

    return () => clearInterval(checkBasket);
  });

    return (
      <div className={classes.container}>
        <Tabs
          className={classes.tabs}
          value={currentTab}
          onChange={handleChange}
          variant={isScrollable ? 'scrollable' : 'standard'}
        >
          <Tab
            className={classes.tab}
            classes={{ selected: classes.tabSelected }}
            value={'/search'}
            label={ t('search') }
            to="/search"
            component={Link}
          />
            <Tab
              className={classes.tab}
              classes={{ selected: classes.tabSelected }}
              value={'/basket'}
              label={t('cart')}
              to="/basket"
              component={Link}
            />
            <Badge
              badgeContent={basketCount}
              max={99}
              className={classes.badge}
            >
            </Badge>
          <Tab
            className={classes.tab}
            classes={{ selected: classes.tabSelected }}
            disabled={!isLoggedIn}
            value={'/orders'}
            label={t('orders')}
            to="/orders"
            component={Link}
          />
          <Tab
            className={classes.tab}
            classes={{ selected: classes.tabSelected }}
            disabled={!isLoggedIn}
            value={'/dispatch'}
            label={t('dispatch')}
            to="/dispatch"
            component={Link}
          />
          <Tab
            className={classes.tab}
            classes={{ selected: classes.tabSelected }}
            disabled={!isLoggedIn}
            value={'/balance'}
            label={t('balance')}
            to="/balance"
            component={Link}
          />
          <Tab
            className={classes.tab}
            classes={{ selected: classes.tabSelected }}
            value={'/offers'}
            label={t('offers')}
            to="/offers"
            component={Link}
          />
        </Tabs>
        <Button
          variant="contained"
          size="small"
          className={classes.button}
          href="/contacts"
        >
          {t('contact_us')}
        </Button>
      </div>
    )
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'baseline',
    backgroundColor: theme.palette.custom.pageTabs.tab,
    color: '#fff',
    position: 'relative',
    justifyContent: 'center',
    marginTop: '2px',
    [theme.breakpoints.down('sm')]: {},
  },
  button: {
    position: 'absolute',
    top: '9px',
    right: '24px',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tabs: {
    "& .MuiTabs-indicator": {
      display: "none"
    }
  },
  tab: {
    opacity: 1,
    borderLeft: '1px solid white',
    borderRight: '1px solid white',
    '&:last-child': {
      borderRight: 'none',
    },
    '&:first-child': {
      borderLeft: 'none',
    },
  },
  badge: {
    '& .MuiBadge-badge': {
      color: theme.palette.custom.pageTabs.tabBackground,
      backgroundColor: theme.palette.custom.pageTabs.tabSelected,
      right: theme.spacing(2.5),
      top: theme.spacing(1.5),
    },
  },
  tabSelected: {
    backgroundColor: theme.palette.custom.pageTabs.tabSelected,
    color: '#fff',
  },
});

export default withWidth()(withStyles(styles)(PageTabs));
