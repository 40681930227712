import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';

import Rating from "material-ui-rating";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import StarRateIcon from "@material-ui/icons/StarRate";
import QuantityField from "./QuantityField";

const SearchTable = ({ classes, data, showReference, onDataChange, isLoggedIn }) => {
  const { t } = useTranslation();

  function handleQuantityChange(id, quantity) {
    const newData = data.map(value =>
      value.id !== id
        ? value
        : {
            ...value,
            quantity
          }
    );
    onDataChange && onDataChange(newData);
  }

  const currency = data.length ? data[0].currency : "";
  return (
    <div className={`${classes.container} search-table`}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <div>{t('part_number')}</div>
              <div>{t('substituted')}</div>
            </TableCell>
            <TableCell>{t('brand')}</TableCell>
            <TableCell>{t('description')}</TableCell>
            <TableCell>{t('days')}</TableCell>
            <TableCell>{t('price_with_currency', { currency })}</TableCell>
            {isLoggedIn && <TableCell>{t('quantity')}</TableCell>}
            <TableCell>{t('available')}</TableCell>
            <TableCell className={classes.weightCell}>
              <div>{t('weight_kg')}</div>
              <div>{t('volume_kg')}</div>
            </TableCell>
            {showReference && <TableCell>{t('reference')}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(value => (
            <TableRow key={value.id}>
              <TableCell>
                <div>{value.partNumber}</div>
                <div className={classes.warnValue}>
                    {value.inpPartNumber !== value.partNumber ? value.inpPartNumber : ''}
                </div>
              </TableCell>
              <TableCell>{value.brand}</TableCell>
              <TableCell>{value.description}</TableCell>
              <TableCell>{value.days}</TableCell>
              <TableCell>{value.price}</TableCell>
              {isLoggedIn && <TableCell>
                <QuantityField
                  className={classes.quantityField}
                  id={value.id}
                  quantity={value.quantity}
                  onChange={handleQuantityChange}
                />
              </TableCell>}
              <TableCell>
                <div>{value.available?value.available:value.price?t('for_order'):t('n_a')}</div>
                {value.booking==='STOD' && <div>{t('own_stock')}</div>}
              </TableCell>
              <TableCell>
                <div className={classes.weightValue}>{value.weightKg}</div>
                <div>{value.volumeKg}</div>
              </TableCell>
              {showReference && <TableCell>{value.yourReference}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const styles = theme => {
  const colors = theme.palette.custom.searchPage;
  return {
    container: {
      flex: "1 0 0px"
    },
    weightCell: {
      whiteSpace: "nowrap"
    },
    weightValue: {
      borderBottom: `1px solid ${colors.weightValueBorder}`
    },
    pointValue: {
      borderBottom: `1px solid ${colors.weightValueBorder}`
    },
    ratingCell: {
      textAlign: "left",
      padding: 20
    },
    rating: {
      display: "flex"
    },
    ratingIcon: {
      width: 20,
      height: 20
    },
    ratingIconButton: {
      padding: 1
    },
    quantityField: {
      alignItems: "center"
    },
    warnValue: {
        color: 'red'
    }
  };
};

export default withStyles(styles)(SearchTable);
