import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as userApi from '../api/user-api';
import * as dictionaryApi from '../api/dictionary-api';
import * as basketApi from '../api/basket-api';
import { getCookie } from '../utils/cookies';
import { setCookie } from '../utils/cookies';
import {
  PageHeader,
  PageTabs,
  PageContent,
  PageFooter,
  LoginDialog,
  SignUpDialog,
  UserDataDialog
} from '../components';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';


const Page = ({ classes, children, render }) => {

  const { t } = useTranslation();
  const [languageCode, setLanguageCode] = useState(
    localStorage.getItem('languageCode') || 'en'
  );

  const [isLoginDialogOpen, setLoginDialogOpen] = useState(false);
  const [isSignUpDialogOpen, setSignUpDialogOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!getCookie('access_token'));
  const [loginError, setLoginError] = useState('');
  const [signUpError, setSignUpError] = useState('');
  const [passResetError, setPassResetError] = useState('');
  const [isPasswordReset, setPasswordReset] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [isUserDataDialogOpen, setUserDataDialogOpen] = useState(false);
  const [isUserDataSubmitting, setUserDataSubmitting] = useState(false);
  const [submitUserDataError, setSubmitUserDataError] = useState('');

  const [countryList, setCountryList] = useState([]);

  async function getCountryList() {

      try {
        const result = await dictionaryApi.countries();

        setCountryList(
          result.map((row) => ({
            title: row['name'],
            value: row['code']
          }))
        );
      }
      catch (error) {

      }
  }

  const [currencyList, setCurrencyList] = useState([]);

  async function getCurrencyList() {

      try {
        const result = await dictionaryApi.currencies();

        setCurrencyList(
          result.map((row) => ({
            title: row['name'],
            value: row['code']
          }))
        );
      }
      catch (error) {

      }
  }

  const languageList = [
    {
      value: 'en',
      title: 'Eng',
    },
    {
      value: 'ru',
      title: 'Руc',
    },
  ];

  const languageSelect = (code) => {
    localStorage.setItem('languageCode', code);
    setLanguageCode(code);
    i18n.changeLanguage(code);
  };

  const [userData, setUserData] = useState({
      username: '',
      countryCode: '',
      zipCode: '',
      city: '',
      state: '',
      street: '',
      building: '',
      flat: '',
      phone: '',
      contactPerson: '',
      contactPhone: '',
      contactEMail: '',
      trn: '',
      currencyCode: ''
    });

  useEffect(() => {

    const checkLogin = setInterval(
      () => {
        if (isLoggedIn !== !!getCookie('access_token')) {
          setIsLoggedIn(!isLoggedIn)
          getUserData();
        }
      },500
    );

    getCountryList();
    getCurrencyList();

    return () => clearInterval(checkLogin);
  }, [isLoggedIn]);

  function handleLoginDialogClose() {
    setLoginDialogOpen(false);
  }

  function handleLoginDialogOpen() {
    setLoginDialogOpen(true);
  }

  async function getUserData() {


    setUserData({
          username: '',
          countryCode: '',
          zipCode: '',
          city: '',
          state: '',
          street: '',
          building: '',
          flat: '',
          phone: '',
          contactPerson: '',
          contactPhone: '',
          contactEMail: '',
          trn: '',
          currencyCode: ''
    });

    const result = await userApi.getData();

    if (result !==null && result.length !==0)
    {
       setCookie("currency", result[0].currencyCode);

        setUserData({
          username: result[0].username,
          countryCode: result[0].countryCode,
          zipCode: result[0].zipCode,
          city: result[0].city,
          state: result[0].state,
          street: result[0].street,
          building: result[0].building,
          flat: result[0].flat,
          phone: result[0].phone,
          contactPerson: result[0].contactPerson,
          contactPhone: result[0].contactPhone,
          contactEMail: result[0].contactEMail,
          trn: result[0].trn,
          currencyCode: result[0].currencyCode,
        });
    }

  }

  async function handleLogin(email, password) {
    try {
      setSubmitting(true);
      await userApi.login(email, password);
      setLoginDialogOpen(false);
      setSubmitting(false);
      window.location.reload();
    } catch (error) {
      setLoginError(error.message);
      setSubmitting(false);
    }
  }

  async function handleSignUp(user) {
    try {
      setSubmitting(true);
      await userApi.signUp(user);
      setSignUpDialogOpen(false);
      setSubmitting(false);
      window.location.reload();
    } catch (error) {
      setSignUpError(error.message);
      setSubmitting(false);
    }
  }

  async function handleSubmitUserData(data) {
    try {
      setUserDataSubmitting(true);
      await userApi.setData(data);
      setUserDataDialogOpen(false);
      setUserDataSubmitting(false);
      window.location.reload();
    } catch (error) {
      setSubmitUserDataError(error.message);
      setUserDataSubmitting(false);
    }
  }

  function handleSignUpDialogClose() {
    setSignUpDialogOpen(false);
  }

  function handleSignUpDialogOpen() {
    setSignUpDialogOpen(true);
  }

  function handleUserDataDialogOpen() {
    setUserDataDialogOpen(true);
  }

  function handleUserDataDialogClose() {
    setUserDataDialogOpen(false);
  }

  async function handleLogout() {
    try {
      await userApi.logout();
      window.location.reload();
    } catch (error) {
      // empty
    }

  }

  async function handlePasswordReset(email) {
    try {
      await userApi.reset(email);
      setPasswordReset(true);
    } catch (err) {
      setPassResetError(err.message);
    }
  }

  return (
    <div className={classes.page}>
      <PageHeader
        companyName={window.COMPANY_NAME}
        companyAddress={window.COMPANY_ADDRESS}
        isLoggedIn={isLoggedIn}
        onLogin={handleLoginDialogOpen}
        onSignUp={handleSignUpDialogOpen}
        onLogout={handleLogout}
        onUserData={handleUserDataDialogOpen}
        languageCode={languageCode}
        languageList={languageList}
        languageSelect={languageSelect}
      />
      <PageTabs
          isLoggedIn={isLoggedIn}
      />
      <PageContent>{render ? render({ isLoggedIn }) : children}</PageContent>
      <PageFooter />
      {isLoginDialogOpen && (
        <LoginDialog
          error={loginError || passResetError}
          isSubmitting={isSubmitting}
          isPasswordReset={isPasswordReset}
          onClose={handleLoginDialogClose}
          onLogin={handleLogin}
          onPasswordReset={handlePasswordReset}
        />
      )}
      {isSignUpDialogOpen && (
        <SignUpDialog
          error={signUpError}
          isSubmitting={isSubmitting}
          onClose={handleSignUpDialogClose}
          onSubmit={handleSignUp}
        />
      )}
      {isUserDataDialogOpen && (
              <UserDataDialog
                error={submitUserDataError}
                isSubmitting={isUserDataSubmitting}
                onClose={handleUserDataDialogClose}
                onSubmit={handleSubmitUserData}
                countryList={countryList}
                currencyList={currencyList}
                userProfileData={userData}
              />
      )}
    </div>
  );
};

const styles = (theme) => ({
  page: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '1280px',
    },
  },
});

export default withStyles(styles)(Page);
